.page-container {
    padding: 0 28px;
}

.spacer {
    height: 30px;
}

.spacer-small {
    height: 20px;
}

.small-gaps {
    column-gap: 10px;
}