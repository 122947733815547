#devicesGrid{
    height: calc(100svh - 200px);
    --ag-cell-horizontal-border: solid var(--ag-row-border-color);
}

.tableHeader{
    font-size: 18px;
}

.storeConfigurationContainer {
    padding: 20px;
}