/*
.errored-integrations {
    width: 350px;
}

.errored-integrations .loader {
    width: 20px;
}

 */

 .errored-integrations{
    width: 1500px;
 }
 .small-gaps {
    column-gap: 10px;
 }

.error{
    padding-left:10px;
}

.inline-div:not(:last-child) {
    padding-right: 10px;
}

.cell:not(:first-child){
    padding-left: 20px;
}