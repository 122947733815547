.ticket-item {
    align-items: center;
    height: 50px;
    display: flex;
}

.ticket-description div.MuiTextField-root {
    margin: 0;
}

.create-ticket .loader {
    width: 20px;
}

.create-ticket .loader-container {
    width: inherit;
    height: inherit;
}

button.create-ticket-btn.MuiButton-root {
    text-transform: none;
}

.txt-area textarea{
    padding:0 15px;
}

.close-btn:hover {
    cursor: pointer;
    border: 1px solid gray;
    border-radius:3px;
}
.close-icon-container {
    display: flex;
    justify-content: end;
}