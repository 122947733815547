.card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

.card-title {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

#subTitle{
    font-size: 10px;
}

