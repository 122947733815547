.lrsCalendar{
    border-radius: 10px;
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow{
    border-radius: 10px;
}
.MuiDataGrid-columnHeader, .MuiDataGrid-scrollbarFiller, .MuiDataGrid-filler{
    background-color: white;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar {
    --sb-size: 10px;
    width: var(--sb-size)
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-track {
    background: var(--bs-gray-200);
    border-radius: 4px;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb {
    background: var(--bs-gray-400);
    border-radius: 4px;

}

@supports not selector(::-webkit-scrollbar) {
    .MuiDataGrid-scrollbar {
        scrollbar-color: var(--bs-gray-400) var(--bs-gray-200);
    }
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{
    margin: 0;
}
