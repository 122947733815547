.number-wan{
    flex: 1;
    align-items: center;
}

.number-wan .number {
   font-size: 40px;
   font-weight: 500;
   padding: 10px, 0px, 10px, 0px;
   line-height: 50px;
}

.number-wan .text {
    font-size: 18px;
    font-weight: 500;
    padding-top: 20px;

}

.icon-number-wan{
    text-align: center;
    /* transform: scale(1.3); */
}

.down-number {
    width: 350px;
}
.down-number .update-date{
    font-size: 12px;
    color: var(--bs-gray-500);
}
