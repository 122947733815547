.card-container.wan-status-list{
    width: 350px;
    max-height: 430px;
}

.table-wan{
    max-height: 300px;
    overflow-y: scroll;
    --sb-size: 8px;
}

.table-wan::-webkit-scrollbar {
    width: var(--sb-size)
}

.table-wan::-webkit-scrollbar-track {
    background: var(--bs-gray-200);
    border-radius: 4px;
}

.table-wan::-webkit-scrollbar-thumb {
    background: var(--bs-gray-400);
    border-radius: 4px;

}

.table-wan>div:nth-child(odd) {
    background-color: var(--bs-gray-100);
  }

@supports not selector(::-webkit-scrollbar) {
    .table-wan {
        scrollbar-color: var(--bs-gray-400) var(--bs-gray-200);
    }
}

