.logo {
    height: 90px;
}
.top-lvl-item{
    padding-left: 15px;
}
ul.nav-children{
    padding-left: 60px;
}
ul.nav-children div{
    font-size: 12px;
}