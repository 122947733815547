div.errored-integrations {
    width: 850px;
}

.grid-container {
    padding: 12px;
}

    .grid-container:nth-child(even) {
        background-color: #F8F8F8;
    }

.grid-header {
    padding: 0px, 0px, 4px, 0px;
    font-family: Figtree;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
}

/* HTML: <div class="loader"></div> */
.errored-integrations .loader {
    width: 20px;
}

.errored-integrations .loader-container {
    width: inherit;
    height: inherit;
}

.spacer-small {
    height: 20px;
}

