
 .small-gaps {
    column-gap: 10px;
 }

 .release-title{
   padding-bottom: 20px;
 }

 .circle{
   list-style-type: circle;
 }

 a{
  text-decoration: none;
 }