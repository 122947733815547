div.store-info{
    width: 100%;
    /*  */
}

span.MuiCheckbox-sizeMedium{
    padding:0;
}

.cell:not(:first-child){
    padding-left: 20px;
}

.back-to-list-link{
    display: inline-block;
    margin-bottom: 10px;
}

.tableHeader{
    font-size: 18px;
}

#grid-container {
    --ag-cell-horizontal-border: solid var(--ag-row-border-color);
    height: calc(100svh - 200px);
    padding: 0px 50px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track, .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track{
    background-color: var(--bs-gray-200);
    border-radius: 4px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb, .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb{
    background-color: var(--bs-gray-400);
    border-radius: 4px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar, .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
    width: 17px;
}

@supports not selector(::-webkit-scrollbar) {
    .ag-body-horizontal-scroll-viewport, .ag-body-vertical-scroll-viewport {
        scrollbar-color: var(--bs-gray-400) var(--bs-gray-200);
    }
}

.notes-field{
padding: 14px;
border-radius: 10px;
border: 1px solid #D2D1D3;
}

.notes-field:hover{
    border-color: rgba(0, 0, 0, 0.6)
}

.notes-field:focus{
    border-color: #fc1000;
    outline: none;
}

.notes-field-label{
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.6);
}