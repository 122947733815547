.sync-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

}

.sync-container-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: right;
}

.lookup-container {
    padding: 0 28px;
}

#employee-lookup-title {
    font-family: Figtree;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    padding-bottom: 10px;
}

.employee-name-container>.employee-maintenance,
.employee-maintenance-name {
    padding: 4px 12px 12px 12px;
}

.details-subheader .emploee-lookup-small-font,
.details-header .emploee-lookup-small-font {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.user-profile-card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.text-input {
    border-radius: 10px;
    border: 1px solid #E1E2E7;
    padding: 12px;
    font-size: 14px;
    width: 100%;
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
}

.employee-details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

.employee-details-item-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.employee-details-item {
    flex-grow: 1;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    min-width: 271px;
}

.details-section-header {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 15px;
    line-height: 15px;
    text-align: center;
    border-bottom: 1px solid #D2D1D3;
}

.employee-name-container .MuiGrid-root.MuiGrid-container:nth-of-type(even) {
    background-color: #F8F8F8;

}

.details-section-subheader {
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
}

.data-display {
    display: flex;
    padding: 12px 10px;
    font-family: Figtree;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.employee-lookup .location-list, .font {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.data-display:nth-of-type(even) {
    background-color: #F8F8F8;
}

.data-display-list {
    list-style-type: none;
    padding: 5px 0;
}

.ul-list{
    padding: 0;
}

.fixed-label {
    width: 100px;
    padding-right:15px;
}

.fixed-label-job-name {
    width: 190px;
    padding-right:15px;
}


.spacer {
    height: 30px;
}

.spacer-small {
    height: 20px;
}

.employee-details-divider {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.12);
}

.employee-details-item-header {
    display: flex;
}

.employee-name-container {
    flex-grow: 1;
    width: 400px;
}

 .employee-update-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width:800px;
}

.details-row {
    align-items: baseline;
}

.employee-lookup .grid-table-row{
    padding: 0px;
}

.table-row:nth-child(even){
    background-color: #F8F8F8;
}

.table-rows-container{
    background-color: #ffffff;
}